import {Fragment, useContext, useEffect, useState} from "react";
import IndirectCosts from "./IndirectCosts";
import LinkedToCustomerTaskFilters from "./LinkedToCustomerTaskFilters";
import {AuthContext} from "../../contexts/AuthContext";
import SmallSpinner from "../SmallSpinner";
import Error from "../Alerts/Error";
import EstimatedInvoiceValue from "./EstimatedInvoiceValue/EstimatedInvoiceValue";

function LinkedToCustomerTask() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [results, setResults] = useState({});
	const [creationDateFrom, setCreationDateFrom] = useState('');
	const [creationDateTo, setCreationDateTo] = useState('');
	const [deliveryDateFrom, setDeliveryDateFrom] = useState('');
	const [deliveryDateTo, setDeliveryDateTo] = useState('');
	const [paymentDateFrom, setPaymentDateFrom] = useState('');
	const [paymentDateTo, setPaymentDateTo] = useState('');
	const [invoiceDateFromCustomer, setInvoiceDateFromCustomer] = useState('');
	const [invoiceDateToCustomer, setInvoiceDateToCustomer] = useState('');
	const [creationDateFromCustomerTask, setCreationDateFromCustomerTask] = useState('');
	const [creationDateToCustomerTask, setCreationDateToCustomerTask] = useState('');
	const [deliveryDateFromCustomerTask, setDeliveryDateFromCustomerTask] = useState('');
	const [deliveryDateToCustomerTask, setDeliveryDateToCustomerTask] = useState('');
	const [invoiceDateFrom, setInvoiceDateFrom] = useState('');
	const [invoiceDateTo, setInvoiceDateTo] = useState('');
	const [customer, setCustomer] = useState([]);

	const {token, setToken, setUser} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setLoading(true);
			setError('');
			try {
				const jsonDashboard = await fetchDashboard();
				if (isMounted) {
					if (jsonDashboard.error) {
						setError(jsonDashboard.error);
					}
					else {
						setResults(jsonDashboard.results);
					}
				}
			}
			catch (e) {
				console.error(e);
				isMounted && setError('Failed to reach the server.');
			}
			finally {
				isMounted && setLoading(false);
			}

		})();
		return () => {
			isMounted = false;
		};
	}, [creationDateFrom, creationDateTo, deliveryDateFrom, deliveryDateTo, paymentDateFrom, paymentDateTo,
		invoiceDateFromCustomer, invoiceDateToCustomer, creationDateFromCustomerTask, creationDateToCustomerTask,
		deliveryDateFromCustomerTask, deliveryDateToCustomerTask,
		invoiceDateFrom, invoiceDateTo, customer.toString()]);

	async function fetchDashboard() {
		let params = new URLSearchParams();

		params.append('linkedToCustomerTask', '1');

		if (creationDateFrom) {
			params.append('creationDateFrom', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateTo', creationDateTo);
		}
		if (deliveryDateFrom) {
			params.append('deliveryDateFrom', deliveryDateFrom);
		}
		if (deliveryDateTo) {
			params.append('deliveryDateTo', deliveryDateTo);
		}
		if (paymentDateFrom) {
			params.append('paymentDateFrom', paymentDateFrom);
		}
		if (paymentDateTo) {
			params.append('paymentDateTo', paymentDateTo);
		}
		if (invoiceDateFromCustomer) {
			params.append('invoiceDateFromCustomer', invoiceDateFromCustomer);
		}
		if (invoiceDateToCustomer) {
			params.append('invoiceDateToCustomer', invoiceDateToCustomer);
		}
		if (creationDateFromCustomerTask) {
			params.append('creationDateFromCustomerTask', creationDateFromCustomerTask);
		}
		if (creationDateToCustomerTask) {
			params.append('creationDateToCustomerTask', creationDateToCustomerTask);
		}
		if (deliveryDateFromCustomerTask) {
			params.append('deliveryDateFromCustomerTask', deliveryDateFromCustomerTask);
		}
		if (deliveryDateToCustomerTask) {
			params.append('deliveryDateToCustomerTask', deliveryDateToCustomerTask);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFrom', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateTo', invoiceDateTo);
		}
		params.append('customer', customer);

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/dashboard?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

    function addCurrencies(eur, usd) {
        if (eur && usd) {
            return eur + usd;
        }
        else if (eur && !usd) {
            return eur;
        }
        else if (!eur && usd) {
            return usd;
        }
        else {
            return 0;
        }
    }

	return (
		<div className={"m-2 border p-4 bg-white shadow-sm"}>
			{error && <Error message={error} /> }

			<h3 className={"text-center"}>Linked to a customer task</h3>

			<table className={"table table-bordered table-sm table-striped m-0 border shadow-sm"}>
				<thead className={"table-secondary"}>
				<LinkedToCustomerTaskFilters creationDateFrom={creationDateFrom} creationDateTo={creationDateTo}
											 setCreationDateFrom={setCreationDateFrom} setCreationDateTo={setCreationDateTo}
											 deliveryDateFrom={deliveryDateFrom} deliveryDateTo={deliveryDateTo}
											 setDeliveryDateFrom={setDeliveryDateFrom} setDeliveryDateTo={setDeliveryDateTo}
											 paymentDateFrom={paymentDateFrom} paymentDateTo={paymentDateTo}
											 setPaymentDateFrom={setPaymentDateFrom} setPaymentDateTo={setPaymentDateTo}
											 invoiceDateFromCustomer={invoiceDateFromCustomer} invoiceDateToCustomer={invoiceDateToCustomer}
											 setInvoiceDateToCustomer={setInvoiceDateToCustomer} setInvoiceDateFromCustomer={setInvoiceDateFromCustomer}
											 creationDateFromCustomerTask={creationDateFromCustomerTask} setCreationDateFromCustomerTask={setCreationDateFromCustomerTask}
											 creationDateToCustomerTask={creationDateToCustomerTask} setCreationDateToCustomerTask={setCreationDateToCustomerTask}
											 invoiceDateFrom={invoiceDateFrom} invoiceDateTo={invoiceDateTo} setInvoiceDateFrom={setInvoiceDateFrom} setInvoiceDateTo={setInvoiceDateTo}
											 deliveryDateFromCustomerTask={deliveryDateFromCustomerTask} setDeliveryDateFromCustomerTask={setDeliveryDateFromCustomerTask}
											 deliveryDateToCustomerTask={deliveryDateToCustomerTask} setDeliveryDateToCustomerTask={setDeliveryDateToCustomerTask}
											 customer={customer} setCustomer={setCustomer}/>
				</thead>
				<tbody>
					<tr>
						<th rowSpan={7} className={"align-middle"}>Customer tasks invoiced to the customer</th>
						<th className={"text-center"}>Total value of customer tasks invoiced (<i>not influenced by filters on the right</i>)</th>
						<th>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.invoicedToTheCustomer.totalInvoiceAmountEUR, results.invoicedToTheCustomer.totalInvoiceAmountUSD).toLocaleString()}</Fragment>}</th>
						<th>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</th>
					</tr>
					<tr>
						<th className={"text-center"}>Total final  value of customer tasks invoiced (<i>not influenced by filters on the right</i>)</th>
						<th>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{results.invoicedToTheCustomer.totalFinalAmountEUR.toLocaleString()}</Fragment>}</th>
						<th>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36;{results.invoicedToTheCustomer.totalFinalAmountUSD.toLocaleString()}</Fragment>}</th>
					</tr>
					<tr>
						<th className={"text-center"}>Total value of jobs</th>
						<th>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.invoicedToTheCustomer.totalEUR, results.invoicedToTheCustomer.totalUSD).toLocaleString()}</Fragment>}</th>
						<th>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</th>
					</tr>
					{/*<tr>
						<th className={"text-center"}>Estimated bonus</th>
						<th>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{results.invoicedToTheCustomer.estimatedBonusEUR.toLocaleString()}</Fragment>}</th>
						<th>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36;{results.invoicedToTheCustomer.estimatedBonusUSD.toLocaleString()}</Fragment>}</th>
					</tr>*/}
					<tr>
						<td>Jobs created but not yet delivered by the freelancers</td>
						<td>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.invoicedToTheCustomer.jobsCreatedNotDeliveredEUR, results.invoicedToTheCustomer.jobsCreatedNotDeliveredUSD).toLocaleString()}</Fragment>}</td>
						<td>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</td>
					</tr>
					<tr>
						<td>Jobs delivered but not yet invoiced by the freelancers</td>
						<td>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.invoicedToTheCustomer.jobsDeliveredNotInvoicedEUR, results.invoicedToTheCustomer.jobsDeliveredNotInvoicedUSD).toLocaleString()}</Fragment>}</td>
						<td>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</td>
					</tr>
					<tr>
						<td>Jobs invoiced by the freelancers but <u>NOT</u> paid by Datamundi to the freelancer yet</td>
						<td>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.invoicedToTheCustomer.jobsInvoicedNotPaidEUR, results.invoicedToTheCustomer.jobsInvoicedNotPaidUSD).toLocaleString()}</Fragment>}</td>
						<td>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</td>
					</tr>
					<tr>
						<td>Jobs invoiced by the freelancers and <u>PAID</u> by Datamundi to the freelancer</td>
						<td>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.invoicedToTheCustomer.jobsInvoicedPaidEUR, results.invoicedToTheCustomer.jobsInvoicedPaidUSD).toLocaleString()}</Fragment>}</td>
						<td>{loading || results.invoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</td>
					</tr>
				</tbody>
				<tbody>
					<tr>
						<th rowSpan={5} className={"align-middle"}>Customer tasks <u>NOT</u> invoiced to the customer</th>
						<th className={"text-center"}>Total value of jobs</th>
						<th>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.notInvoicedToTheCustomer.totalEUR, results.notInvoicedToTheCustomer.totalUSD).toLocaleString()}</Fragment>}</th>
						<th>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</th>
					</tr>
					{/*<tr>
						<th className={"text-center"}>Estimated bonus</th>
						<th>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{results.notInvoicedToTheCustomer.estimatedBonusEUR.toLocaleString()}</Fragment>}</th>
						<th>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36;{results.notInvoicedToTheCustomer.estimatedBonusUSD.toLocaleString()}</Fragment>}</th>
					</tr>*/}
					<tr>
						<td>Jobs created but not yet delivered by the freelancers</td>
						<td>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.notInvoicedToTheCustomer.jobsCreatedNotDeliveredEUR, results.notInvoicedToTheCustomer.jobsCreatedNotDeliveredUSD).toLocaleString()}</Fragment>}</td>
						<td>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</td>
					</tr>

					<tr>
						<td>Jobs delivered but not yet invoiced by the freelancers</td>
						<td>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.notInvoicedToTheCustomer.jobsDeliveredNotInvoicedEUR, results.notInvoicedToTheCustomer.jobsDeliveredNotInvoicedUSD).toLocaleString()}</Fragment>}</td>
						<td>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</td>
					</tr>
					<tr>
						<td>Jobs invoiced by the freelancers but <u>NOT</u> paid by Datamundi to the freelancer yet</td>
						<td>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.notInvoicedToTheCustomer.jobsInvoicedNotPaidEUR, results.notInvoicedToTheCustomer.jobsInvoicedNotPaidUSD).toLocaleString()}</Fragment>}</td>
						<td>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</td>
					</tr>
					<tr>
						<td>Jobs invoiced by the freelancers and <u>PAID</u> by Datamundi to the freelancer</td>
						<td>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.notInvoicedToTheCustomer.jobsInvoicedPaidEUR, results.notInvoicedToTheCustomer.jobsInvoicedPaidUSD).toLocaleString()}</Fragment>}</td>
						<td>{loading || results.notInvoicedToTheCustomer === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</td>
					</tr>
				</tbody>
				<tbody>
				<tr>
					<td></td>
					<th className={"text-center"}>CASH</th>
					<th>{loading || results.totalCashEUR === undefined ? <SmallSpinner />: <Fragment>&euro;{addCurrencies(results.totalCashEUR, results.totalCashUSD).toLocaleString()}</Fragment>}</th>
					<th>{loading || results.totalCashUSD === undefined ? <SmallSpinner />: <Fragment>&#36; N.A.</Fragment>}</th>
				</tr>
				<EstimatedInvoiceValue />
				</tbody>
			</table>
		</div>
	);
}

export default LinkedToCustomerTask;
